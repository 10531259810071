import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Chemical vapor deposition tools`}</h4>
    <p>{`Chemical vapor deposition (CVD) tools create a chemical vapor that deposits films on the wafer atom-by-atom or molecule-by-molecule. CVD is the most widely used deposition technique in chip fabrication. It is used to deposit most dielectrics (a type of insulator), silicon, and some metals. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      